/**
 * Downloads a pdf and sets filename from content-disposition.
 */
export const downloadFile = async (fileResponse) => {
  const fileName = decodeURI(fileResponse.headers['content-disposition']?.split('"')[1]);

  const url = window.URL.createObjectURL(new Blob([fileResponse.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  link.parentNode?.removeChild(link);
};

export const downloadFileFromUrl = (url: string) => {
  const link = document.createElement('a');
  link.href = url;
  document.body.appendChild(link);
  link.click();
  link.parentNode?.removeChild(link);
};