import {knest, kooperAuth} from './kooperConfig';
import {getToken} from './auth';
import {downloadFile, downloadFileFromUrl} from '../utils/downloadHelpers';

require('dotenv').config();

export const updateKooperPassword = async (oldPassword, password) => {
  const payload = {
    currentPassword: oldPassword,
    newPassword: password,
    confirmNewPassword: password,
  };
  return kooperAuth.post('/auth/users/current/change-password', payload);
};

export const getKooperUser = async () => {
  return kooperAuth.get('/auth/users/current/info');
};

export const startImpersonation = async (assetId) => {
  return knest.post(`/users/current/start/impersonating/${assetId}`);
};

export const stopImpersonation = async () => {
  return knest.delete('/users/current/stop/impersonating');
};

export const setLanguage = async (lng) => {
  return knest.post(`/users/current/language/${lng}`);
};

export const getCompanies = async (searchValue) => {
  if (searchValue.length > 1) {
    const searchParams = {filter: {where: {companyName: {contains: searchValue, mode: 'insensitive'}}, limit: 10}};
    const response = await knest.get('/companies', {params: searchParams});
    return response.data.page;
  }
  return [];
};

export const getFilteredBundles = async (page, limit, statuses, orderBy?) => {
  const filter = {statusKey: 'open'};
  return getBundleList(page, limit, statuses, filter, orderBy);
};

export const getAuctionBundles = async (page, limit, filterArg) => {

  const filter = {
    limit, page, ...filterArg,
  };
  const res = await knest.get('/auction-bundle-teasers', {params: {filter}});
  return res.data;
};

export const getAuctionBundleById = async (id) => {
  const filter = {
    include: {
      auctionTeaser: true,
      supplier: {include: {supplierSupplierGroup: true}},
      auctionBundleOrderedPartConfigTeasers: {
        include: {
          bundleOrderedPartConfigProcessTeasers: true,
        },
      },
    },
  };
  const res = await knest.get(`/auction-bundle-teasers/${id}`, {params: {filter}});
  return res.data;
};
export const getPartnerPartConfigQuotes = async (filter = {}, {page = 1, limit = 25, disablePagination = false} = {}) => {
  const {data} = await knest.get('/partner-part-config-quote-teasers', {
    params: {
      filter: {
        ...filter,
        ...(!disablePagination && {limit, page}),
      },
      disablePagination,
    },
  });
  return data;
};

export const updatePartnerPartConfigBatchSizeQuote = async (ppcqId: number, ppcbsqId: number, {quote, leadTime}: { quote?: number, leadTime?: number } = {}) => {
  return knest.patch(`/partner-part-config-quotes/${ppcqId}/partner-part-config-batch-size-quotes/${ppcbsqId}`, {
    quote,
    leadTime,
  });
};

export const submitQuotes = async (id: number) => {
  return knest.post(`/partner-quotes/${id}/submit-quotes`);
};

export const getReorderedOpcs = async (bundleId, bopcId) => {
  const res = await knest.get(`/bundles/${bundleId}/bundle-ordered-part-configs/${bopcId}/reorders`);
  return res.data;
};

export const getReorderedPartsPerBundle = async (bundleIds) => {
  return await knest.get(`/bundles/reorders?bundleIds=[${bundleIds}]`);
};
export const getBundleList = async (page, limit, statuses, filterArg, orderBy) => {
  const where = filterArg ? {AND: [{statusKey: {in: statuses}}, filterArg]} : {statusKey: {in: statuses}};

  const filter = {
    orderBy, limit, page, where,
    include: {bundleStatuses: true},
  };
  const res = await knest.get('/bundle-teasers', {params: {filter}});
  return res.data;
};

export const searchBundles = async (searchValue) => {
  const filter = {
    where: {
      OR: [
        {
          bundleKid: {
            contains: searchValue,
            mode: 'insensitive',
          },
        },
      ],
    },
  };
  const res = await knest.get('/bundle-teasers', {params: {filter}});
  return res.data;
};

/**
 * Get the number of bundles for the different status keys for the dashboard.
 * @return {Promise<Object>}
 */
export const getDashboardData = async (where): Promise<number> => {
  const res = await knest.get('/bundle-teasers/count', {params: {where}});
  return res.data;
};

export const getBundlePageData = async (bundleId) => {
  const filter = {
    include: {
      currentBundleStatus: {
        include: {
          bundleStatusFeatureValues: true,
        },
      },
      bundleStatuses: {
        include: {
          bundleStatusFeatureValues: true,
        },
      },
      bundleAttachments: {
        include: {
          kaktusAttachment: {
            include: {
              attachmentType: true,
            },
          },
        },
      },
      supplier: true,
      parentBundle: true,
      bundleOrderedPartConfigs: {
        include: {
          openBookData: true,
          featureValues: true,
          attachments: {
            include: {
              kaktusAttachment: {
                include: {
                  attachmentType: true,
                },
              },
            },
          },
          orderedPartConfig: {
            include: {
              orderedPartConfigOrderedAttachments: {
                include: {
                  orderedAttachment: {
                    include: {
                      fileFormat: true,
                      attachmentType: true,
                    },
                  },
                },
              },
              orderedPart: {
                include: {
                  orderedPartAttachments: {
                    include: {
                      orderedAttachment: {
                        include: {
                          fileFormat: true,
                          attachmentType: true,
                        },
                      },
                    },
                  },
                  orderedPartPartFeatures: {
                    include: {
                      partFeature: true,
                    },
                  },
                },
              },
              orderedPartConfigProcesses: {
                include: {
                  orderedManufacturingAnalysis: true,
                  process: true,
                  orderedPartConfigProcessFeatureValues: {
                    include: {
                      processFeature: true,
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  };

  return knest.get(`/bundle-teasers/${bundleId}`, {params: {filter}});
};

export const downloadProjectFiles = async (bundleId) => {
  //TODO: Add language filter

  const filesResponse = await knest.get(`/bundles/${bundleId}/files`, {
    responseType: 'arraybuffer',
  });

  return downloadFile(filesResponse);
};

export const downloadBundleOrderedPartConfigFiles = async (bundleId, bundleOrderedPartConfigId) => {
  //TODO: Add language filter
  const filesResponse = await knest.get(`/bundles/${bundleId}/bundle-ordered-part-configs/${bundleOrderedPartConfigId}/files`, {
    responseType: 'arraybuffer',
  });
  return downloadFile(filesResponse);
};

export const downloadAttachment = async (bundleId, type) => {
  const userToken = getToken().substr(7);
  window.open(`${process.env.REACT_APP_KNEST_KAKTUS_URL}/bundles/${bundleId}/pdfs/${type}?access_token=${userToken}`);
};

export const downloadDhlCsv = async (bundleId) => {
  const filesResponse = await knest.get(`/bundles/${bundleId}/dhl-csv`, {
    responseType: 'arraybuffer',
  });
  return downloadFile(filesResponse);
};

export const downloadBundleAttachment = async (bundleId, type) => {

  const pdf = await knest.get(`/bundles/${bundleId}/attachments/${type}`, {responseType: 'arraybuffer'});
  const url = window.URL.createObjectURL(new Blob([pdf.data], {
    type: 'application/pdf',
  }));
  window.open(url);
};

export const openPdf = async (bundleId, bundleOrderedPartConfigId) => {
  const filesResponse = await knest.get(`/bundles/${bundleId}/bundle-ordered-part-configs/${bundleOrderedPartConfigId}/files`, {
    responseType: 'blob',
    params: {
      fileFormat: 'pdf',
    },
  });

  if (filesResponse.headers['content-type'] === 'application/pdf') {
    const fileBlob = new Blob([filesResponse.data], {
      type: 'application/pdf',
    });

    const url = URL.createObjectURL(fileBlob);
    window.open(url);
  }
};

export const updateBundleStatus = async (bundleId, statusKey, statusFeatures?, details?) => {
  return knest.post(`/bundles/${bundleId}/status-update`, {statusKey, statusFeatures, details});
};

export const getStatusFeatureSelectOptions = async (statusFeatureKey) => {
  return knest.get('/kaktus-status-features/findUnique',
    {params: {filter: {where: {key: statusFeatureKey}, include: {statusFeatureSelectOptions: true}}}});
};

export const uploadFile = async (bundleId, attachmentType, formData) => {
  return knest.post(`/bundles/${bundleId}/attachments/${attachmentType}/upload`, formData);
};

export const sendContactEmail = async (bundleId, message) => {
  return knest.post(`/bundles/${bundleId}/contact`, {message});
};

export const patchOrCreateSupplierProcess = async (supplierProcess) => {
  return knest.patch(`/self-service/update`, supplierProcess);
};

export const getProcessesForSelfService = async () => {
  return knest.get(`/self-service`);
};

export const insertSeenAt = async (bundleId) => {
  return knest.patch(`/bundles/${bundleId}/seenAt`);
};

export const constructBundleOrderedPartConfigThumbnailURL = (bundleId, bundleOrderedPartConfigId) => {
  const userToken = getToken().split('Bearer ')[1];
  return `${process.env.REACT_APP_KNEST_KAKTUS_URL}/bundles/${bundleId}/bundle-ordered-part-configs/${bundleOrderedPartConfigId}/thumbnail?access_token=${userToken}`;
};

export const constructBundleOrderedPartConfigStlURL = (bundleId, bundleOrderedPartConfigId) => {
  const userToken = getToken().split('Bearer ')[1];
  return `${process.env.REACT_APP_KNEST_KAKTUS_URL}/bundles/${bundleId}/bundle-ordered-part-configs/${bundleOrderedPartConfigId}/files?fileFormat=stl&returnFirst=true&access_token=${userToken}`;
};

export const getBundleWithKumberasId = (kumberasId) => {
  return knest.get(`/bundle-teasers/findUnique`, {params: {filter: {where: {kumberasId}}}});
};

export const uploadBopcFile = async (bundleId, bundleOrderedPartConfigId, attachmentType, formData) => {
  return knest.post(`/bundles/${bundleId}/bundle-ordered-part-configs/${bundleOrderedPartConfigId}/attachments/${attachmentType}/upload`, formData);
};

export const deleteBopcAttachment = async (bundleId, bundleOrderedPartConfigId, bopcaId) => {
  return knest.delete(`/bundles/${bundleId}/bundle-ordered-part-configs/${bundleOrderedPartConfigId}/attachments/${bopcaId}`);
};

export const downloadBopcFiles = async (bundleId, bundleOrderedPartConfigId) => {
  const filesResponse = await knest.get(`/bundles/${bundleId}/bundle-ordered-part-configs/${bundleOrderedPartConfigId}/attachments`, {
    responseType: 'arraybuffer',
  });
  await downloadFile(filesResponse);
};

export const downloadPartnerQuoteFiles = (partnerQuoteId: number) => {
  const userToken = getToken().split('Bearer ')[1];
  return downloadFileFromUrl(`${process.env.REACT_APP_KNEST_KAKTUS_URL}/partner-quotes/${partnerQuoteId}/files?access_token=${userToken}`);
};

export const downloadPartnerPartConfigQuotePdf = async (partnerPartConfigQuoteId: number) => {
  const filesResponse = await knest.get(`/partner-part-config-quotes/${partnerPartConfigQuoteId}/files`, {
    params: {
      fileFormatExtension: 'pdf',
      returnFirst: true,
    },
    responseType: 'blob',
  });

  const fileBlob = new Blob([filesResponse.data], {
    type: 'application/pdf',
  });

  const url = URL.createObjectURL(fileBlob);
  window.open(url);
};
export const downloadPartnerPartConfigQuoteFiles = async (partnerPartConfigQuoteId: number, fileFormatExtension?: string, returnFirst?: boolean) => {
  const filesResponse = await knest.get(`/partner-part-config-quotes/${partnerPartConfigQuoteId}/files`, {
    params: {
      fileFormatExtension,
      returnFirst,
    },
    responseType: 'arraybuffer',
  });

  await downloadFile(filesResponse);
};

export const getFile = async (bundleId, bundleOrderedPartConfigId) => {
  const filesResponse = await knest.get(`/bundles/${bundleId}/bundle-ordered-part-configs/${bundleOrderedPartConfigId}/files`, {
    responseType: 'arraybuffer',
    params: {
      fileFormat: 'pdf',
    },
  });
  return filesResponse.data;
};

export const updateOpenBookData = async (bundleId, obdId, key, value) => {
  return knest.put(`/bundles/${bundleId}/open-book-data/${obdId}`, {key, value});
};

export const updateActualMaterialPrice = async (bundleId, bopcId, price) => {
  return knest.put(`/bundles/${bundleId}/bundle-ordered-part-configs/${bopcId}/actual-material-price`, {actualMaterialPrice: price});
};

export const downloadOpenBookDataCsv = async (bundleId) => {

  const filesResponse = await knest.get(`/bundle-teasers/${bundleId}/open-book-data/csv`, {
    responseType: 'arraybuffer',
  });
  await downloadFile(filesResponse);
};

export const downloadCustomsDocuments = async (bundleId, data) => {
  const filesResponse = await knest.post(`/bundles/${bundleId}/documents/customs`, data, {responseType: 'blob'});
  await downloadFile(filesResponse);
};

export const getPerformanceDashboard = async () => {
  return knest.get('companies/supplier-performance-dashboard', {
    responseType: 'arraybuffer',
  });
};

export const upsertAuctionBid = async (bundleId, bopcId, costs) => {
  return knest.patch(`/bundles/${bundleId}/bundle-ordered-part-configs/${bopcId}/auction-price/${costs}`);
};

export const constructPdfDonwloadLink = (bundleId, bopcId) => {
  const formattedToken = getToken().substring(7); // Remove "Bearer " from the token
  return `${process.env.REACT_APP_KNEST_KAKTUS_URL}/bundles/${bundleId}/bundle-ordered-part-configs/${bopcId}/files?fileFormat=pdf&access_token=${formattedToken}`;
};

export const getInvoiceData = async () => {
  return knest.get('bundle-ordered-part-configs/ready-for-invoicing');
};

export const uploadInvoice = async (form) => {
  return knest.post('partner-transaction-documents', form);
};
