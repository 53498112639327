import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {Card, Chip, Grid, TextField, Typography, Button, IconButton, Tooltip, Theme} from '@mui/material';
import Loading from '../../components/Loading/Loading';
import {downloadPartnerPartConfigQuoteFiles, downloadPartnerPartConfigQuotePdf, downloadPartnerQuoteFiles, getPartnerPartConfigQuotes, submitQuotes, updatePartnerPartConfigBatchSizeQuote} from '../../services/kooper';
import {Download, FolderZip, OpenInNew, QueryBuilderOutlined, TableView} from '@mui/icons-material';
import {DataGridPro, GridColDef, useGridApiRef} from '@mui/x-data-grid-pro';
import {getPartDimensions} from '../../components/BundleDetailsPage/CollapsablePartDetails/CollapsablePartDetails.PartInfo';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import {withTheme} from '@mui/styles';
import {addWorkingHoursToDate} from '../../utils/helpers';
import {GridApiPro} from '@mui/x-data-grid-pro/models/gridApiPro';

const MySwal = withReactContent(Swal);

type TableInputFieldProps = {
  initialValue?: string | null,
  onChange: (value: number | null) => any,
  quotingOpen: boolean,
  modifierText: string | JSX.Element,
  isInteger?: boolean,
}
type TableInputFieldState = {
  value: string | null,
}

class TableInputField extends Component<TableInputFieldProps, TableInputFieldState> {
  constructor(props) {
    super(props);

    this.state = {
      value: props.initialValue || '',
    };
  }

  onChange = (value: string | null, commit: boolean = false) => {
    let parsedValue = !value?.trim() ? null : this.props.isInteger ? parseInt(value) : parseFloat(value);

    this.setState({
      value: parsedValue?.toString() || '',
    });

    if (commit && parsedValue) this.props.onChange(parsedValue);
  };

  render() {
    const {quotingOpen, modifierText} = this.props;
    if (this.props.initialValue === undefined) return <>-</>;
    if (!quotingOpen) return this.state.value ? <Typography>{this.state.value} {modifierText}</Typography> : <Typography>-</Typography>;

    return <TextField
      variant={'outlined'}
      size={'small'}
      type={'number'}
      onWheel={({target}) => (target as HTMLElement).blur()}
      value={this.state.value}
      onChange={({target: {value}}) => this.onChange(value)}
      onBlur={({target: {value}}) => this.onChange(value, true)}
      InputProps={{
        endAdornment: <Typography>{modifierText}</Typography>,
      }}
    />;
  }
}

type Props = {
  t: Function,
  navigate: Function
  theme: Theme,
  gridApiRef: React.MutableRefObject<GridApiPro>,
}
type State = {
  loading: boolean,
  partnerQuoteId: number,
  partnerQuote: { [key: string]: any } | null,
  partnerPartConfigQuotes: any[],
  partnerQuoteTime: { days: number, hours: number, minutes: number, seconds: number },
  columns: GridColDef[],
  quoteSubmitted: boolean,
}

class RFQDetails extends Component<Props, State> {
  timer: any;
  alpha: string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

  constructor(props) {
    super(props);

    this.timer = null;

    this.state = {
      loading: true,
      partnerQuoteId: Number.parseInt(window.location.pathname.split('/')[2]),
      quoteSubmitted: false,
      partnerPartConfigQuotes: [],
      partnerQuote: null,
      partnerQuoteTime: {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      },
      columns: [],
    };
  }

  async componentDidMount() {
    // Get RFQ data for the current supplier
    const partnerPartConfigQuotes = await getPartnerPartConfigQuotes({
      where: {partnerQuoteId: this.state.partnerQuoteId},
      include: {
        partnerQuote: true,
        partnerPartConfigBatchSizeQuotes: {orderBy: {batchSize: 'asc'}},
        partConfigQuote: {
          include: {
            partConfig: {
              include: {
                material: {include: {materialNames: true}},
                part: {include: {partPartFeatures: true}},
                partConfigProcesses: {include: {process: true}},
              },
            },
          },
        },
      },
    }, {disablePagination: true});

    const partnerQuote = partnerPartConfigQuotes[0]?.partnerQuote || null;
    const quoteSubmitted = partnerPartConfigQuotes.every(ppcq => ppcq.statusKey !== 'quoting_in_progress');

    if (partnerQuote?.statusKey === 'quoting_in_progress' && !quoteSubmitted) {
      this.timer = setInterval(() => {
        const now = new Date();
        const diff = addWorkingHoursToDate(partnerQuote.createdAt, 72).toDate().getTime() - now.getTime();

        if (diff <= 0) {
          this.setState({
            partnerQuoteTime: {
              days: 0,
              hours: 0,
              minutes: 0,
              seconds: 0,
            },
          });

          clearInterval(this.timer);
          return;
        }

        this.setState({
          partnerQuoteTime: {
            days: Math.floor(diff / (1000 * 60 * 60 * 24)),
            hours: Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
            minutes: Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60)),
            seconds: Math.floor((diff % (1000 * 60)) / 1000),
          },
        });
      }, 1000);
    }

    const columns: GridColDef[] = [
      {
        field: 'rowNumber',
        headerName: '#',
        width: 40,
        align: 'center',
        headerAlign: 'center',
        valueGetter: params => this.state.partnerPartConfigQuotes.indexOf(params.row) + 1,
        renderCell: params => <Typography>{params.value}</Typography>,
      },
      {
        field: 'partName',
        headerName: this.props.t('auctions:auction_package_details_header_part_name'),
        width: 200,
        valueGetter: params => params.row.partConfigQuote.partConfig.part.name,
        renderCell: params => <Typography>
          <a href={'#'} onClick={() => downloadPartnerPartConfigQuotePdf(params.row.id)}>
            {params.value} <OpenInNew fontSize={'inherit'} />
          </a>
        </Typography>,
      },
      {
        field: 'partArticleNr',
        headerName: this.props.t('auctions:auction_package_details_header_article_number'),
        width: 200,
        valueGetter: params => params.row.partConfigQuote.partConfig.part.articleNr,
        renderCell: params => <Typography>{params.value}</Typography>,
      },
      {
        field: 'partDrawingNr',
        headerName: this.props.t('auctions:auction_package_details_header_drawing_number'),
        width: 200,
        valueGetter: params => params.row.partConfigQuote.partConfig.part.drawingNr,
        renderCell: params => <Typography>{params.value}</Typography>,
      },
      {
        field: 'materialNames',
        headerName: this.props.t('auctions:auction_package_details_header_material'),
        width: 200,
        valueGetter: params => params.row.partConfigQuote.partConfig.materialIsUrl ? params.row.partConfigQuote.partConfig.materialName : [
          params.row.partConfigQuote.partConfig.materialName,
          params.row.partConfigQuote.partConfig.material?.key,
          ...(params.row.partConfigQuote.partConfig.material?.materialNames.map(mn => mn.name) || []),
        ].filter(Boolean).join('/'),
        renderCell: params => <Typography overflow={'scroll'} maxHeight={'85%'}>{params.row.partConfigQuote.partConfig.materialIsUrl ? <a href={params.value} target={'_blank'}>{params.value}</a> : params.value}</Typography>,
      },
      {
        field: 'mainProcesses',
        headerName: this.props.t('auctions:auction_package_details_header_main_processes'),
        width: 200,
        valueGetter: params => params.row.partConfigQuote.partConfig.partConfigProcesses.filter(pcp => pcp.process.class === 'process').map(pcp => pcp.process.key),
        renderCell: params => this.renderProcessPills(params.value),
        valueFormatter: params => params.value.map(process => this.props.t(`processes:${process}`)).join(', '),
      },
      {
        field: 'postProcesses',
        headerName: this.props.t('auctions:auction_package_details_header_post_processes'),
        width: 250,
        valueGetter: params => params.row.partConfigQuote.partConfig.partConfigProcesses.filter(pcp => pcp.process.class === 'post_process').map(pcp => pcp.process.key),
        renderCell: params => this.renderProcessPills(params.value),
        valueFormatter: params => params.value.map(process => this.props.t(`processes:${process}`)).join(', '),
      },
      {
        field: 'dimensions',
        headerName: this.props.t('rfq:rfq_table_header_dimensions'),
        width: 250,
        valueGetter: params => getPartDimensions(params.row.partConfigQuote.partConfig.part.partPartFeatures),
        renderCell: params => <Typography>{params.value}</Typography>,
      },
      {
        field: 'weight',
        headerName: this.props.t('rfq:rfq_table_header_weight'),
        width: 150,
        valueGetter: params => {
          const weightFeature = params.row.partConfigQuote.partConfig.part.partPartFeatures.find(oppf => oppf.partFeatureKey === 'weight');
          let weight = weightFeature?.value?.trim() || weightFeature?.valueAuto?.trim() as unknown as number;
          if (weight > 1000) return `${parseFloat((weight / 1000).toFixed(2))} kg`;
          return `${parseFloat(weight).toFixed(1)} g`;
        },
        renderCell: params => <Typography>{params.value}</Typography>,
      },
    ];

    const quotingOpen = partnerQuote.statusKey === 'quoting_in_progress' && !quoteSubmitted;

    const maxBatchSizeCount = Math.max(...partnerPartConfigQuotes.map(ppcq => ppcq.partnerPartConfigBatchSizeQuotes.length));
    for (let i = 0; i < maxBatchSizeCount; i++) {
      columns.push({
        field: `batchSize${i + 1}`,
        headerName: `${this.props.t('rfq:rfq_table_header_batch_size')} ${this.alpha[i]}`,
        width: 120,
        valueGetter: params => params.row.partnerPartConfigBatchSizeQuotes[i]?.batchSize,
        renderCell: params => <Typography>{params.value || '-'}</Typography>,
      });

      columns.push({
        field: `price${i + 1}`,
        headerName: this.props.t('rfq:rfq_table_header_price_per_piece'),
        width: 200,
        valueGetter: params => {
          return params.row.partnerPartConfigBatchSizeQuotes[i]?.quote;
        },
        renderCell: params => <TableInputField
          modifierText={<>&euro;</>}
          onChange={(value) => this.updatePartConfigBatchSizeQuote(params.row.id, params.row.partnerPartConfigBatchSizeQuotes[i].id, 'quote', value)}
          quotingOpen={quotingOpen}
          initialValue={params.value}
        />,
        disableExport: true,
      });

      columns.push({
        field: `leadTime${i + 1}`,
        headerName: this.props.t('rfq:rfq_table_header_lead_time'),
        width: 150,
        valueGetter: params => params.row.partnerPartConfigBatchSizeQuotes[i]?.leadTime,
        renderCell: params => <TableInputField
          modifierText={this.props.t('rfq:rfq_table_header_days')}
          onChange={(value) => this.updatePartConfigBatchSizeQuote(params.row.id, params.row.partnerPartConfigBatchSizeQuotes[i].id, 'leadTime', value)}
          quotingOpen={quotingOpen}
          initialValue={params.value}
          isInteger
        />,
        disableExport: true,
      });
    }

    if (!quotingOpen) {
      columns.push({
        field: 'status',
        headerName: this.props.t('rfq:rfq_table_header_status'),
        width: 250,
        valueGetter: params => params.row.statusKey,
        renderCell: params => this.renderPartnerPartConfigQuoteStatusCell(params.value),
        disableExport: true,
      });
    }

    columns.push({
      field: 'actions',
      headerName: '',
      width: 50,
      align: 'center',
      renderCell: (params) => {
        return <Tooltip title={this.props.t('rfq:rfq_download_files')}>
          <IconButton onClick={() => downloadPartnerPartConfigQuoteFiles(params.row.id)}><Download /></IconButton>
        </Tooltip>;
      },
      disableExport: true,
    });

    columns.forEach(col => col.sortable = false);

    this.setState({
      loading: false,
      partnerPartConfigQuotes,
      partnerQuote,
      quoteSubmitted,
      columns,
    });
  }

  renderProcessPills = (processes: string[]) => {
    return <Grid container sx={{height: '85%', overflow: 'scroll', padding: '3px'}} alignItems={'center'}>
      {processes.map(process =>
        <Grid item m={0.25}>
          <Chip
            label={this.props.t(`processes:${process}`)}
            sx={{
              height: '20px',
              backgroundColor: '#B1E7D5',
              fontSize: '12px',
              fontWeight: '400',
              color: '#131C3E',
            }}
          />
        </Grid>,
      )
      }
    </Grid>;
  };

  renderPartnerPartConfigQuoteStatusCell = (statusKey: string) => {
    const {t} = this.props;

    return <Grid container sx={{height: '85%', overflow: 'scroll', padding: '3px'}} alignItems={'center'}>
      <Grid item m={0.25}>
        <Chip
          label={t(`rfq:partner_part_config_quote_status_${statusKey}`)}
          sx={{
            height: '20px',
            fontSize: '12px',
            fontWeight: '400',
          }}
          color={statusKey === 'quoting_completed' ? 'success' : statusKey === 'quoting_rejected' ? 'error' : 'default'}
        />
      </Grid>
    </Grid>;
  };

  updatePartConfigBatchSizeQuote = async (ppcqId: number, ppcbsqId: number, field: 'quote' | 'leadTime', value: number | null) => {
    await updatePartnerPartConfigBatchSizeQuote(ppcqId, ppcbsqId, {[field]: value});
    this.setState({
      partnerPartConfigQuotes: this.state.partnerPartConfigQuotes.map(ppcq => {
        if (ppcq.id === ppcqId) {
          ppcq.partnerPartConfigBatchSizeQuotes = ppcq.partnerPartConfigBatchSizeQuotes.map(ppcbsq => {
            if (ppcbsq.id === ppcbsqId) {
              ppcbsq[field] = value;
            }
            return ppcbsq;
          });
        }
        return ppcq;
      }),
    });
  };

  submitQuotes = async () => {
    const {isConfirmed} = await MySwal.fire({
      title: this.props.t('rfq:rfq_submit_confirmation_title'),
      text: this.props.t('rfq:rfq_submit_confirmation_text'),
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: this.props.t('rfq:rfq_submit_cancel'),
      reverseButtons: true,
      confirmButtonColor: this.props.theme.palette.success.main,
      cancelButtonColor: this.props.theme.palette.info.main,
    });

    if (isConfirmed) {
      await submitQuotes(this.state.partnerQuoteId);
      window.location.reload();
    }
  };

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  render() {
    const {t} = this.props;

    return (
      <Grid container sx={{height: '100%'}} alignContent={'flex-start'} pl={2.5} pr={6.25}>
        {
          this.state.loading
            ? (<><Loading pageHeight={'100%'} /></>)
            : this.state.partnerQuote && (<>
            {/* Page Title */}
            <Grid item container xs={12} mt={3.75} mb={2.5}>
              <Grid item container xs={6} alignItems={'center'}>
                <Grid item xs={12}>
                  <Typography color={'primary'} variant={'h3'} sx={{fontWeight: 700, fontSize: '14px'}}>
                    {t(`rfq:partner_quote_status_${this.state.partnerQuote.statusKey}`).toUpperCase()}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant={'h3'} sx={{color: '#131C3E'}}>
                    {t('rfq:request_for_offer_id')} {this.state.partnerQuote.id}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container xs={6} alignItems={'center'} justifyContent={'flex-end'} spacing={3}>
                <Grid item xs={'auto'}>
                  <Button
                    onClick={() => this.props.gridApiRef.current.exportDataAsCsv({fileName: `RFO-${this.state.partnerQuote?.id}`})}
                    variant={'outlined'}
                    color={'secondary'}
                    size={'large'}
                  >
                    {t('auctions:auction_package_download_csv')}&nbsp;<TableView fontSize={'small'} />
                  </Button>
                </Grid>
                <Grid item xs={'auto'}>
                  <Button
                    color={'secondary'}
                    variant={'outlined'}
                    size={'large'}
                    onClick={() => downloadPartnerQuoteFiles(this.state.partnerQuoteId)}
                  >
                    {t('rfq:rfq_download_all_files')}&nbsp;<FolderZip fontSize={'medium'} />
                  </Button>
                </Grid>
                {this.state.partnerQuote.statusKey === 'quoting_in_progress' && !this.state.quoteSubmitted &&
                  <Grid item xs={'auto'}>
                    <Card sx={{width: '200px', height: '60px', padding: '10px'}}>
                      <Grid container height={'100%'} alignItems={'center'}>
                        <Grid item xs={3}>
                          <QueryBuilderOutlined sx={{width: 30, height: 30}} color={'secondary'} />
                        </Grid>
                        <Grid item xs={9}>
                          <Typography color={'primary'} variant={'h3'} sx={{fontWeight: 800, fontSize: '12px'}}>
                            {t('auctions:combi_auction_card_countdown').toUpperCase()}
                          </Typography>
                          <Typography color={'black'} sx={{fontWeight: 600, fontSize: '14px'}}>
                            {this.state.partnerQuoteTime.days}D {this.state.partnerQuoteTime.hours}H {this.state.partnerQuoteTime.minutes}M {this.state.partnerQuoteTime.seconds}S
                          </Typography>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                }
              </Grid>
            </Grid>
            {/* Page Content */}
            <Grid item container xs={12} sx={{height: 'calc(100vh - 180px) !important'}} overflow={'scroll'} pb={2} spacing={2} justifyContent={'flex-end'}>
              <Grid item xs={12} sx={{height: 'calc(100% - 50px)'}}>
                <DataGridPro
                  columns={this.state.columns}
                  rows={this.state.partnerPartConfigQuotes}
                  rowHeight={70}
                  sx={{
                    overflow: 'hidden',
                    border: '0.5px solid #D1D5DB',
                    '.MuiDataGrid-cell': {
                      fontSize: '12px',
                      fontWeight: 400,
                      whiteSpace: 'normal !important',
                      wordWrap: 'break-word !important',
                      height: '50px',
                      overflow: 'scroll',
                    },
                    '.MuiDataGrid-cellContent': {
                      overflow: 'scroll',
                    },
                    '.MuiDataGrid-row': {backgroundColor: '#FFF'},
                    '.MuiDataGrid-columnHeader': {backgroundColor: '#F3F4F6', fontSize: '15px'},
                    '.MuiDataGrid-columnHeaderTitle': {fontWeight: 400},
                    '.MuiDataGrid-pinnedColumnHeaders': {boxShadow: 0},
                    '.MuiDataGrid-pinnedColumns': {boxShadow: 0},
                  }}
                  disableSelectionOnClick
                  hideFooter
                  disableColumnMenu
                  disableColumnResize
                  pinnedColumns={{
                    left: ['rowNumber', 'partName'],
                  }}
                  apiRef={this.props.gridApiRef}
                  columnVisibilityModel={{
                    requiredBatchSizes: false,
                  }}
                />
              </Grid>
              {
                this.state.partnerQuote.statusKey === 'quoting_in_progress' && !this.state.quoteSubmitted && <Grid item xs={'auto'}>
                  <Button onClick={() => this.submitQuotes()}>{t('rfq:rfq_submit')}</Button>
                </Grid>
              }
            </Grid>
          </>)
        }
      </Grid>
    );
  }
}

const withGridApiRef = Component => props => {
  const gridApiRef = useGridApiRef();
  return <Component gridApiRef={gridApiRef} {...props} />;
};
export default withGridApiRef(withTranslation(['auctions', 'rfq'])(withTheme(RFQDetails)));
